/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  function isMobile() {
    return (/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera);
  }

  function setDesktopBannerHeight(el) {

    if ( isMobile() ) {
        return;
    }

    if (!el) {
      throw 'Target element is required.';
    }

    var winHeight = window.innerHeight;
    $(el).height(winHeight);
  }

  function setMobileBannerHeight(el) {
    if ( !isMobile() ) {
      return;
    }

    var winHeight = window.innerHeight;
    var winWidth = window.innerWidth;
    var bannerHeight = (winHeight > winWidth) ? winHeight : winWidth;
    $(el).css({ 'min-height': bannerHeight, 'height': bannerHeight});
  }

  function disableTimeSlot(fieldClass, times) {
  	if (! fieldClass) {
  		return;
  	}

  	var el = $('.'+fieldClass);

  	el.find('option').prop('disabled', false);

  	if (! (times instanceof Array)) {
  		return el.find('option[value="'+times.trim()+'"]').prop('disabled', true);
  	}

   	times.map(function(time) {
   		el.find('option[value="'+time.trim()+'"]').prop('disabled', true);
   	});

  }

  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $("#download-nav-tabs > li").first().addClass('active');
        var navId =  $("#download-nav-tabs > li a").first().attr('tab-id');

        $("#download-tab-panes > div").each(function(e,v){
          if($(v).attr('id') == navId){
            $(v).addClass('active');
          }
        });

        $("#nav-bar-collapsed-button").on('click',function(){
          if($(".navbar-collapse").hasClass('show')){
            $(".navbar-collapse").removeClass('show');
          }
          else{
            $(".navbar-collapse").addClass('show');
          }
        });

        $(".nav > li").on('click',function(){
          $("ul").removeClass('selected');
          $(this).find("ul").addClass('selected');
        });

        $(".custom-colors li").each(function(index,value){
          var that = $(value);
          var color = that.attr('class');
          that.css({"background-color":"#"+color});
        });

        $(".custom-colors li").on('click',function(){
          $(".siding #editable-image .bg").css('background',"#"+$(this).attr('class'));
        });

        window.sr = ScrollReveal({reset: false});
        sr.reveal('.scrollRevealDownloads',80);

        $('[data-toggle="tooltip"]').tooltip();

        $("#printClicked").on('click',function(e){
          e.preventDefault;


          var originalContents = document.body.innerHTML;
          var printContents='';
          $('.printableArea').each(function(ndx,val){
            var that = this;
            if($(that).hasClass('fp-upgradeOptionsNoStyle')){
              $(that).addClass('print-format')
            }
            $(val).find('*').each(function(key,value) {
              var style = value.style;
              var properties = [];
              for(var property in style) {
                if($(value).css(property)) {
                  properties.push(property + ':' + $(value).css(property));
                }
              }

              if(!$(that).hasClass('removeStyle')){
                value.style.cssText = properties.join(';');
              }
            });
            printContents+=$(this).html();
          });

          document.body.innerHTML = printContents;

          window.print();

          document.body.innerHTML = originalContents;
        });

        $('#accordion').on('show.bs.collapse', function () {
          $('#accordion .in').collapse('hide');
        });

        var videoId = '';
        var videoName = '';
        var videoUrl = '';
        $(document).on('click','a[data-toggle="modal"]',function(){
          videoId = $(this).attr('video-id');
          videoName = $(this).attr('video-name');
          videoUrl = $(this).attr('video-url');
        });

        $('#myModal').on('hidden.bs.modal', function () {
          $("#videoBody,#myModalLabel").empty();

          $("#myModalLabel").html(videoName);
          $("#videoBody").html('');
        });

        $('#myModal').on('shown.bs.modal', function () {
          $("#myModalLabel").html(videoName);

          if(videoId != 'none'){
          	// $('#product-video').unbind();
            $("#videoBody").html('<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube.com/embed/'+videoId+'?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe></div>');
          }
          else {
            $("#videoBody").html('<video id="product-video" style="width:100%; height:auto;" width="400" controls autoplay><source src="'+videoUrl+'" type="video/mp4"><source src="'+videoUrl+'" type="video/ogg">Your browser does not support HTML5 video.</video>');
      		// $('#product-video').bind('ended', playNextVideo);
          }
        });

        function setHomeSliderHeight() {
          if (window.innerWidth < 992) {
            var winHeight = window.innerHeight;
            var navbarHeader = $(".navbar-header").height();
            var topMobileNav = $(".top-mobile-nav").height();
            winHeight = winHeight - (navbarHeader + topMobileNav);
            $(".navbar-collapse.collapse").attr('style','height:'+winHeight+"px !important");
          }
          else {
            $(".navbar-collapse.collapse").attr('style',"height:auto !important");
          }

        }
        setHomeSliderHeight();
        $(window).resize(function() {
          setHomeSliderHeight();
        });
        
        if ($( '#field_free-estimates' ).length || $('#field_free-estimates2').length || $('#field_schedule_free-estimates').length){

        	$('.reserve-timeslot').css('display','none');

	        $('#field_free-estimates,#field_free-estimates2,#field_schedule_free-estimates').on('change keydown', function() {
  				  var date = new Date($(this).val());
            var day = date.getDay();

            if (date == 'Invalid Date' || ! date) {
            	$('.reserve-timeslot').css('display','none');
            	return $('.reserve-timeslot').val('');
            }

            $('.reserve-timeslot').css('display','block');

            if(day == 5){
            	disableTimeSlot('reserve-timeslot',[
            		'4:00 PM',
            		'5:00 PM',
            		'6:00 PM',
            		'7:00 PM',
          		]);
            }
            if (day == 6){
            	disableTimeSlot('reserve-timeslot',[
            		'3:00 PM',
            		'4:00 PM',
            		'5:00 PM',
            		'6:00 PM',
            		'7:00 PM',
          		]);
            }
            if(day != 6 && day != 5){
              $('.reserve-timeslot option').prop('disabled', false);
            }
        });
	    }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.flexslider').flexslider({
          slideshowSpeed: 10000,
          animation: "slide",
          controlNav: true,
          initDelay: 0,
        });

        setMobileBannerHeight('.flexslider .slides');
        setDesktopBannerHeight('.flexslider .slides');
        $(window).resize(function() {
          setDesktopBannerHeight('.flexslider .slides');
        });
      },
      finalize: function() {

        $(".youtube-player").on('click',function(){
          var videoId = $(this).attr('yt-id');
          var modalTitle = $(this).attr('modal-title');
          var linkUrl = $(this).attr('link-url');

          $("#myModalTesti h2").html(modalTitle);
          if(linkUrl == null){
            $("#myModalTesti .modal-body").html("<div class=\"embed-responsive embed-responsive-16by9\"><iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/"+videoId+"?rel=0&autoplay=1\" frameborder=\"0\" allowfullscreen></iframe></div>");
          }
          else {
            $("#myModalTesti .modal-body").html('<video style="width:100%; height:auto;" width="400" controls autoplay><source src="'+linkUrl+'" type="video/mp4"><source src="'+linkUrl+'" type="video/ogg">Your browser does not support HTML5 video.</video>');
          }

        });

        $('#myModalTesti').on('hidden.bs.modal', function () {
          $("#myModalTesti .modal-body").html('');
        })

      }

    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'testimonials': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize:function(){
        $(".youtube-player").hover(function(){
          $(this).removeClass('grayscale');
        },function(){
          $(this).addClass('grayscale');
        });
        $(".youtube-player").on('click',function(){
          var videoId = $(this).attr('yt-id');
          var modalTitle = $(this).attr('modal-title');
          var linkUrl = $(this).attr('link-url');

          $("#myModalTesti h2").html(modalTitle);
          if(linkUrl == null){
            $("#myModalTesti .modal-body").html("<div class=\"embed-responsive embed-responsive-16by9\"><iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/"+videoId+"?rel=0&autoplay=1\" frameborder=\"0\" allowfullscreen></iframe></div>");
          }
          else {
            $("#myModalTesti .modal-body").html('<video style="width:100%; height:auto;" width="400" controls autoplay><source src="'+linkUrl+'" type="video/mp4"><source src="'+linkUrl+'" type="video/ogg">Your browser does not support HTML5 video.</video>');
          }

        });

        $('#myModalTesti').on('hidden.bs.modal', function () {
          $("#myModalTesti .modal-body").html('');
        })

        $("#pauseButton").click(function () {
          if ($(this).attr("id") === "pauseButton") {
            $('#testimonialsCarousel').carousel('pause');
            $(this).attr("id", "playButton");
            $("span",this).toggleClass("glyphicon-play glyphicon-pause");
          } else {
            $('#testimonialsCarousel').carousel('cycle');
            $(this).attr("id", "pauseButton");
            $("span",this).toggleClass("glyphicon-pause glyphicon-play");
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
